@import 'src/assets/globalStyles/variables';

.swap {
  width: 562px;

  @include phone {
    width: calc(100vw - 40px);
  }

  &__title {
    font-family: 'Aeroport';
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 29px;
    margin-bottom: 14px;
  }

  &__body {
    position: relative;
    margin-bottom: 15px;
  }

  &__btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #562539;
    border-radius: 18px;
    width: 64px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 8px solid #44112b;
    z-index: 10;

    & > div {
      width: 11px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 11px;
      height: 17px;
    }

    &:hover {
      cursor: pointer;
      background: #f5e867;

      svg {
        path {
          stroke: #44112b;
        }
      }
    }
  }

  &__info {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & .swap__convert {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #e9d79f;
      margin-right: 6px;
    }

    & .swap__rate {
      font-family: 'SF Pro Display';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #fff1ce;
      opacity: 0.5;
      margin-right: 6px;
    }

    & .swap__info_icon {
      width: 18px;
      height: 18px;
    }
  }
}