@import 'src/assets/globalStyles/variables';

.app {
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/icons/bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.app__bg {
  position: absolute;
  bottom: 0;
  right: 123px;
  width: 335px;
  height: 491px;
  z-index: 0;

  @include phone {
    height: 254px;
    width: 173px;
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
  }
}

.header {
  padding: 45px 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include phone {
    background: #662b41;
    border-radius: 0 0 20px 20px;
    padding: 12px;
    margin-bottom: 36px;
  }

  &__lang {
    @include phone {
      font-family: SF Pro Display;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
    }
  }

  &__switch {
    border: 1px solid rgba(255, 255, 255, 0.35);
    box-sizing: border-box;
    border-radius: 23px;

    &:global(.mobile) {
      display: none;
    }

    @include phone {
      display: none;

      &:global(.mobile) {
        display: block;
        width: auto;
        margin: 0 20px;
      }
    }
  }
}

.body {
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;

  @include phone {
    position: static;
    transform: none;
    margin-top: 17px;
  }
}

.logo {
  height: 72px;

  &__img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
  }

  @include phone {
    height: 52px;
  }
}

.lang {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__flag {
    margin-right: 10px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
  }

  &__countrie {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
  }
}

.tablist {
  display: flex;
  overflow: hidden;

  .tab {
    border-radius: 23px;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 54px;
    padding: 15px 35px;

    @include phone {
      font-family: 'Nekst-SemiBold';
      font-size: 17px;
      line-height: 20px;
      padding: 0;
      flex: 1;
      height: 44px;
      padding: 0;
    }

    &__icon {
      width: 25px;
      height: 25px;
      margin-left: 16px;
    }
  }

  :global(.react-tabs__tab--selected) {
    background: #fff1ce;
    color: #3f0223;

    .tab__icon {
      path {
        stroke: #3f0223;
      }
    }
  }
}


