@import 'src/assets/globalStyles/variables';

.swap {
  &__item {
    background: rgb(93 44 62 / 75%);
    border-radius: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    @include phone {
      padding: 14px 10px;
      height: 78px;
    }

    &:first-of-type {
      margin-bottom: 8px;
    }
    &_input {
      position: relative;

      .input__hint {
        display: none;
      }

      &:global(._isError) {
        & .input {
          border: 1.5px solid #ff4141;

          &__field {
            color: #ff4141;
          }

          &__hint {
            display: block;
            position: absolute;
            bottom: -10px;
            left: 0;
            background: #fff1ce;
            box-shadow: 10px 14px 34px #411930;
            font-family: 'SF Pro Display';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #23161d;
            padding: 14px;
            text-align: left;
            transform: translateY(100%);
            border-radius: 13px;
            z-index: 100;

            &::after {
              content: '';
              position: absolute;
              left: 20px;
              top: 0;
              border: 10px solid $tr;
              border-bottom: 10px solid #fff1ce;
              z-index: 997;
              transform: translateY(-80%);
            }
          }
        }
      }
    }
  }
}

.input {
  border: 1.5px solid $tr;
  border-radius: 13px;
  text-align: right;
  padding: 3px 18px;
  max-width: 226px;
  position: relative;
  z-index: 3;
  border-radius: 15px;
  height: 60px;

  @include phone {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  &:hover {
    border: 1.5px solid #e4d652;
  }

  &__field {
    display: block;
    width: 100%;
    background: $tr;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #ffffff;
    text-align: right;

    @include phone {
      font-weight: bold;
      font-size: 17px;
      line-height: 20px;
    }
  }

  &__converter {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #e9d79f;
  }

  &__precent {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #e9d79f;
    opacity: 0.6;
  }
}

.select {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #58263b;
  border: 1.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  height: 60px;
  width: 201px;
  position: relative;

  @include phone {
    width: 160px;
  }

  &:global(.isLongName) {
    &:hover {
      .select___name_long_hint {
        display: block;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 1.5px solid #e4d652;

    .select__arrow {
      svg {
        path {
          stroke: #e4d652;
        }
      }
    }
  }

  &__icon {
    margin-right: 10px;
    width: 42px;
    height: 40px;
    overflow: hidden;
    object-fit: contain;
  }

  &___name {
    font-family: 'Nekst-Bold';
    font-size: 25px;
    line-height: 29px;
    letter-spacing: -0.01em;
    color: #ffffff;
    max-width: 180px;
    display: block;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 0px;
      top: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(90deg, #58263b00 0%, #58263b 100%);
    }
  }

  .select___name_long_hint {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    font-family: 'Nekst-Bold';
    font-size: 13px;
    line-height: 15px;
    color: #eaeaea;
    background: #706166;
    padding: 6px 12px;
    border-radius: 10px;
    transform: translateY(-100%);

    &::before {
      content: '';
      position: absolute;
      left: 20px;
      bottom: -18px;
      border: 10px solid #ffffff00;
      border-top: 10px solid #706166;
    }
  }

  &__arrow {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
