@import './reset';
@import './variables';

@font-face {
  font-family: 'Aeroport';
  src: url('../fonts/Aeroport.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aeroport';
  src: url('../fonts/Aeroport-Bold.woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/SFProDisplay-Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nekst-Medium';
  src: url('../fonts/Nekst-Medium.woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nekst-Regular';
  src: url('../fonts/Nekst-Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nekst-SemiBold';
  src: url('../fonts/Nekst-SemiBold.woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nekst-Bold';
  src: url('../fonts/Nekst-Bold.woff');
  font-weight: 700;
  font-style: normal;
}

* {
  box-sizing: border-box;
  transition: 0.2s;
}

body {
  color: #3f0223;
  font-family: 'Aeroport';
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background: #502733;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: $tr;
}

::-webkit-scrollbar-thumb {
  background: $white;
  border-radius: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Стили для react-seelct с префиксом lang */
.lang {
  &__indicators {
    svg {
      fill: $white;
    }
  }
  &__control {
    padding: 0 14px 0 14px;
    margin: 0;
    border: 1.5px solid $tr !important;
    background-color: $tr !important;
    cursor: pointer !important;
    outline: none !important;
    border-radius: 13px !important;

    &--is-focused {
      border: 1.5px solid $tr !important;
      box-shadow: none !important;
    }

    &--menu-is-open {
      outline: none !important;
      border: 1.5px solid #e4d652 !important;
      box-shadow: none !important;

      .lang__indicators {
        transform: rotate(180deg);
      }
    }
  }
  &__value-container {
    padding: 0 !important;
    margin: 0 !important;
    margin-right: 7px !important;
  }

  &__single-value {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__placeholder {
    font-size: 14px !important;
    color: #828282 !important;
  }
  &__input {
    input {
      color: $black !important;
      font-size: 14px !important;
    }
  }
  &__menu {
    background: linear-gradient(163.56deg, #663147 -7.86%, #321023 107.64%);
    border-radius: 13px 13px 13px 13px;
    overflow: hidden;

    &-list {
      padding: 0 !important;
    }
  }
  &__option {
    cursor: pointer !important;
    border-radius: 0 !important;
    &--is-focused {
      background: #371224 !important;
    }
    &--is-selected {
      cursor: default !important;
      background: #371224 !important;
    }
  }
  &__indicator-separator {
    display: none;
  }
}
.Toastify__toast-body div{
  color: var(--toastify-text-color-light);
}
