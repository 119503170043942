@import 'src/assets/globalStyles/variables';

.app__connect {
  position: absolute;
  left: 81px;
  bottom: 65px;
  z-index: 2;
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 23px;

.tablist {
  display: flex;
  overflow: hidden;

  .tab {
    border-radius: 23px;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 54px;
    padding: 15px 35px;

    @include phone {
      font-family: 'Nekst-SemiBold';
      font-size: 17px;
      line-height: 20px;
      padding: 0;
      flex: 1;
      height: 44px;
      padding: 0;
    }

    &__icon {
      width: 25px;
      height: 25px;
      margin-left: 16px;
    }
  }

  :global(.react-tabs__tab--selected) {
    background: #fff1ce;
    color: #3f0223;

    .tab__icon {
      path {
        stroke: #3f0223;
      }
    }
  }
}

  @include phone {
    position: fixed;
    left: 0;
    transform: none;
    width: 100%;
    bottom: 0;
    background: #391d25;
    border-radius: 23px 23px 23px 23px;
    border: none;
  }

  .tab {
    font-size: 16px;
    line-height: 22px;

    @include phone {
      font-family: 'Nekst-SemiBold';
      font-size: 15px;
      line-height: 18px;

      &:first-of-type {
        border-radius: 23px 23px 0 23px;
      }

      &:last-of-type {
        border-radius: 23px 23px 23px 0;
      }
    }
  }


}
