$white: #ffffff;
$black: #000000;
$tr: #ffffff00;

$desktop: 1920px;
$laptop: 1366px;
$tablet: 1140px;
$phone: 768px;

@mixin laptop {
  @media all and (max-width: $desktop) {
    @content;
  }
}


@mixin tablet {
  @media all and (max-width: $laptop){
    @content;
  }
}

@mixin phone {
  @media all and (max-width: $tablet) {
    @content;
  }
}
