@import 'src/assets/globalStyles/variables';

$converter-margin: 145px;
$result_margin: 10px;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: #18040fb7;

  @include phone {
    height: 100%;
  }

  &__glow_wrapper {
    width: 80%;
    height: 70%;
    position: relative;

    @include phone {
      width: 100%;
      height: 100%;
      border-radius: 0;
      overflow-y: auto;
    }
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    background: #18040f;
    border-radius: 24px;
    background-image: url(../../assets/icons/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 1px 20px 0px rgb(24 4 15 / 42%);
    overflow: hidden;
    position: relative;

    @include phone {
      border-radius: 0;
      overflow-y: auto;


    }
  }

  &__background {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      310deg,
      rgba(81, 37, 49, 0) 0%,
      rgba(81, 37, 49, 0) 20%,
      rgba(81, 37, 49, 1) 100%
    );
    padding: 48px 54px 48px 54px;
    z-index: 4;
    position: inherit;

    @include phone {
      width: auto;
      height: auto;
      padding: 34px 13px;
      background: linear-gradient(
        310deg,
        rgba(121, 42, 53, 0) 0%,
        rgba(121, 42, 53, 0) 20%,
        rgba(121, 42, 53, 1) 100%
      );
    }
  }

  &__line {
    width: 238px;
    height: 52px;
    background: #e0d05f;
    position: absolute;
    top: -46px;
    left: -50px;
    border-radius: 30px;
    z-index: 5;
  }

  &__glow {
    width: 157px;
    height: 52px;
    background: #e0d05f;
    opacity: 0.4;
    filter: blur(15px);
    border-radius: 20px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    // transform: translateY(-100%) translateX(-100%);
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @include phone {
      flex-direction: column;
      align-items: flex-start;
    }

    &_title {
      font-family: 'Nekst-SemiBold';
      font-size: 24px;
      line-height: 28px;
      color: #fff1ce;
      width: $converter-margin;

      @include phone {
        margin-bottom: 12px;
        font-size: 21px;
        line-height: 25px;
      }
    }
    &_copy {
      display: flex;
      align-items: center;
      border: 1px solid #764f5f;
      border-radius: 13px;
      padding: 15px 13px;

      &:hover {
        cursor: pointer;
        border: 1px solid #f5e867;
      }

      @include phone {
        width: 100%;
        justify-content: space-between;
      }

      &_value {
        display: flex;
        align-items: center;
        font-family: 'SF Pro Display';
        font-style: normal;
        font-size: 27px;
        line-height: 32px;
        color: #ffffff;
        margin-right: 70px;

        @include phone {
          margin-right: 0;
          flex: 1;
          font-size: 18px;
          line-height: 21px;

          span {
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        &_icon {
          width: 42px;
          height: 38px;
          object-fit: contain;
          overflow: hidden;
          margin-right: 16px;

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      &_btn {
        display: flex;
        align-items: center;
        margin-left: 10px;

        span {
          font-family: 'SF Pro Display';
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
          color: #f5e867;

          @include phone {
            display: none;
          }
        }

        &_icon {
          margin-right: 12px;

          @include phone {
            margin-right: 0;
            background: #6c3846;
            border-radius: 10px;
            padding: 10px;
          }

          & > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .to_copy_value {
        font-family: 'SF Pro Display';
        font-size: 21px;
        line-height: 25px;
        color: #ffffff;
        width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        word-break: break-all;

        @include phone {
          font-size: 18px;
          line-height: 21px;
        }
      }
    }
  }

  &__info {
    margin-left: calc(#{$converter-margin} + #{$result_margin});
    display: flex;
    align-items: center;

    &_comission {
      background: #50303a;
      border-radius: 6px;
      font-family: 'SF Pro Display';
      font-size: 14px;
      line-height: 17px;
      color: #d7c593;
      min-height: 33px;
      display: flex;
      align-items: center;
      padding: 9px 14px;
      margin-right: 7px;
    }

    &_cashback {
      background: #e4d652;
      border-radius: 6px;
      font-family: 'SF Pro Display';
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      min-height: 33px;
      display: flex;
      align-items: center;
      padding: 9px 14px;
      margin-right: 7px;
    }

    @include phone {
      display: none;
    }
  }

  &__receive {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @include phone {
      flex-direction: column;
      align-items: flex-start;
    }

    &_title {
      width: calc(#{$converter-margin} + #{$result_margin});
      font-family: 'Nekst-SemiBold';
      font-size: 24px;
      line-height: 28px;
      color: #fff1ce;

      @include phone {
        margin-bottom: 10px;
        font-size: 21px;
        line-height: 25px;
      }
    }

    &_data {
      display: flex;
      align-items: center;
      font-family: 'SF Pro Display';
      font-size: 27px;
      line-height: 32px;
      color: #ffffff;

      @include phone {
        font-size: 18px;
        line-height: 21px;
      }

      & > div {
        display: flex;
        align-items: center;

        @include phone {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &_icon {
        margin-right: 19px;
      }

      &_conv {
        font-family: 'SF Pro Display';
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #e9d79f;
        margin-left: 6px;

        @include phone {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 96px;

    @include phone {
      margin-bottom: 30px;
    }

    &_title {
      display: flex;
      align-items: center;
      font-family: 'Nekst-Regular';
      font-size: 33px;
      line-height: 33px;
      color: #ffffff;

      @include phone {
        align-items: flex-start;
      }

      &_icon {
        margin-right: 20px;

        @include phone {
          margin-top: 5px;
        }

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        svg {
          width: 30px;
          height: 20px;
        }
      }

      strong {
        font-family: 'Nekst-SemiBold';
        margin-right: 10px;
      }
    }
  }

  &__description {
    font-family: 'SF Pro Display';
    font-size: 17px;
    line-height: 20px;
    color: #e9d79f;
    margin-top: 58px;
    max-width: 436px;
    text-align: center;
    margin: 58px auto 0 auto;
  }

  &__security {
    font-family: 'SF Pro Display';
    font-size: 15px;
    line-height: 18px;
    color: #e9d79f;
    display: flex;
    align-items: center;
    position: absolute;
    left: 40px;
    bottom: 40px;

    &_icon {
      margin-right: 10px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        width: 21px;
        height: 25px;
      }
    }

    @include phone {
      display: none;
    }
  }

  &__close {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      cursor: pointer;
    }

    @include phone {
      display: none;
    }
  }

  &__bg {
    position: absolute;
    right: 0px;
    bottom: 80px;

    @include phone {
      display: none;
    }
  }
}

.qr__btn {
  width: 57px;
  height: 57px;
  background: #6c38468f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  margin-left: 11px;

  @include phone {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  &_icon {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.modal__modile__bg {
  @include phone {
    background: linear-gradient(180deg, #4d242f 0%, #391c24 100%);
    border-radius: 20px;
    padding: 25px 20px;
  }
}

.mobile__btn {
  display: none;

  @include phone {
    display: block;
    margin-top: 20%;
  }
}
