@import 'src/assets/globalStyles/variables';

.modal {
  position: absolute;
  left: 30%;
  top: 0;
  transform: translateX(-50%);
  z-index: 99999;
  box-shadow: 2px 18px 20px 9px #0000004d;
  border-radius: 18px;

  @include phone {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    transform: none;
    border-radius: 0;

    .modal__select {
      border-radius: 0;
      &_currency {
        max-height: 75vh;
      }
    }
  }
}

.modal__select {
  position: relative;
  background: linear-gradient(180deg, #562539 0%, #321022 100%);
  border-radius: 18px;
  overflow: hidden;
  height: inherit;

  @include phone {
    background: radial-gradient(
        50% 50% at 50% 50%,
        #3f0223 0%,
        rgba(63, 2, 35, 0) 100%
      ),
      linear-gradient(149.86deg, #3f0223 3.88%, #0a0608 85.27%);
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px 15px 0 15px;
  }

  &_title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
    display: flex;
    align-items: center;

    &_icon {
      margin-right: 8px;
      padding: 5px 10px;
      background: linear-gradient(91.06deg, #cd9348 -5.71%, #f4e867 87.53%);
      border-radius: 12px;
      cursor: pointer;

      svg {
        transform: rotate(90deg);

        path {
          stroke: #1a0410;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &_input {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 22px;

    input {
      border: 1px solid #ffffff;
      border-radius: 12px;
      font-family: 'Nekst-SemiBold';
      font-size: 23px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #e9d79f;
      background-color: $tr;
      padding: 15px 22px;
      caret-color: #ffffff;
      width: 100%;

      &::placeholder {
        color: #e9d79f;
      }
    }
  }

  &_currency {
    max-height: 387px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $tr;
    }

    &::-webkit-scrollbar-thumb {
      background: #ffffff;
      border-radius: 10px;
    }

    .currency {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      padding: 6px 26px 6px 35px;

      &:hover {
        cursor: pointer;
        background-color: #371224;
      }

      &__icon {
        width: 31px;
        height: 31px;
        object-fit: contain;
        overflow: hidden;
        margin-right: 9px;
      }
      &__name {
        font-family: 'SF Pro Display';
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        max-width: 72px;
      }
      &__fullname {
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        margin-left: auto;
        color: #e9d79f;
        max-width: 84px;
        text-align: right;
      }
    }
  }

  &_close {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &_line {
    width: 238px;
    height: 52px;
    background: #e0d05f;
    position: absolute;
    top: -49px;
    right: -50%;
    border-radius: 30px;
    transform: translateX(-50%);

    @include phone {
      top: -47px;
      right: -50px;
      transform: translateX(0%);
    }
  }

  &_glow {
    width: 157px;
    height: 52px;
    background: #e0d05f;
    opacity: 0.4;
    filter: blur(17px);
    border-radius: 20px;
    position: absolute;
    right: 0px;
    top: 0;
    z-index: -1;

    @include phone {
      display: none;
    }
  }
}
