@import 'src/assets/globalStyles/variables';

.btn {
    background: linear-gradient(91.06deg, #cd9348 -5.71%, #f4e867 87.53%);
    border-radius: 25px;
    font-family: 'Nekst-Bold';
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    padding: 17px 0 17px 0;
    color: #23161d;
    width: 100%;
    position: relative;
  
    &:hover {
      background: linear-gradient(91.06deg, #ffe700 -5.71%, #f4e867 87.53%);
      cursor: pointer;
      color: #23161d;
      box-shadow: -1px -9px 13px -11px #ffe700;
    }
  }
  